import { defineStore } from "pinia";
// import { ref, toRefs } from "vue";

export const useOnBoardingStore = defineStore(
  "onboarding",
  () => {
    const account_detail = ref<{
      phone: string;
      nik: string;
      ktp: File | undefined;
    }>({
      phone: "",
      nik: "",
      ktp: null,
    });
    const bank_detail = ref<{
      bank_name: string;
      account_number: string;
      name_of_account_holder: string;
    }>({
      bank_name: "",
      account_number: "",
      name_of_account_holder: "",
    });
    const business_detail = ref<{
      company_name: string;
      brand_name: string;
      npwp: string | null;
      nib: string | null;
    }>({
      company_name: "",
      brand_name: "",
      npwp: "",
      nib: "",
    });

    function $reset() {
      account_detail.value = {
        phone: "",
        nik: "",
        ktp: null,
      };
      bank_detail.value = {
        bank_name: "",
        account_name: "",
        name_of_account_holder: "",
      };
      business_detail.value = {
        company_name: "",
        brand_name: "",
        npwp: "",
        nib: "",
      };
    }

    function set(params: any) {
      console.log("set", params);
      const myStore = useOnBoardingStore();
      const states: any = toRefs(myStore.$state);
      const keys = Object.keys(params);

      keys.forEach((key) => {
        states[key].value = params[key];
      });
    }

    return {
      account_detail,
      bank_detail,
      business_detail,
      $reset,
      set,
    };
  },
  {
    persist: true,
  },
);
